<!-- Menus personalizados -->
<ng-container *ngIf="usrProcess$ | async as usrProcess; else loading">
  <ng-container *ngIf="usrProcess && usrProcess.length > 0; else empty">
    <div class="grid" [class.black]="enTheme === 'black'">
      <div *ngFor="let process of usrProcess" class="grid-btn" [class.black]="enTheme === 'black'"
        (click)="onClickProcess(process, enTheme)">
        <img [src]="process?.UrlIcon" class="icn" />
        <span class="bold">{{ process?.Label }}</span>
        <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #empty>
  <h2>Nenhum registro encontrado.</h2>
</ng-template>
<ng-template #loading>
  <ui-loading-spinner></ui-loading-spinner>
  <span>Carregando...</span>
</ng-template>
