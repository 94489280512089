import { ConfigStateService } from '@medlogic/shared/state-config';
import { takeWhile, mergeMap, map, tap, catchError } from 'rxjs/operators';
import { IAlertMessage } from './../../shared/interface/ialert-message';
import { ProcessoDAL } from './../../shared/model/dal/processo-dal';
import { UsuarioDAL } from '@medlogic/shared/shared-data-access';
import { TabComponent } from '../partial/tab/tab.component';
import { ExecucaoTarefaDAL } from '../../shared/model/dal/execucao-tarefa-dal';
import { IBubble } from '../../shared/interface/ibubble';
import { EnBubbleEvent } from '../../shared/enum/en-bubble-event.enum';
import { NavigationService } from '../../shared/service/navigation.service';
import { ActionService } from '../../shared/service/action.service';
import { ValidatorService } from '../../shared/service/validator.service';
import { CalculatorService } from '../../shared/service/calculator.service';
import { CalculadoraConditionService } from '../../shared/service/calculadora-condition.service';
import { DadoDAL } from '../../shared/model/dal/dado-dal';
import { CalculadoraService } from '../../shared/service/calculadora.service';
import { EnumAtividadeTipo } from '../../shared/enum/enum-atividade-tipo.enum';
import { AtividadeComponenteDAL } from '../../shared/model/dal/atividade-componente-dal';
import { FormGroup, FormBuilder, ValidatorFn } from '@angular/forms';
import { AtividadeDAL } from '../../shared/model/dal/atividade-dal';
import { IAtividade } from '../../shared/interface/iatividade';
import { OcorrenciaDAL } from '../../shared/model/dal/ocorrencia-dal';
import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { Subject } from 'rxjs';
import { DialogConfirmComponent } from '@medlogic/shared/ui/dialog/ui-dialog-confirm';
import { UnsubscribeOnDestroyAdapter, IAtividadeComponenteDAL, IOcorrencia, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { WindowDialogComponent } from '@medlogic/shared/gecore';
import { LibService } from '../../shared/service/lib.service';
import { WsTrackerService } from '@medlogic/shared/shared-data-access';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { IBasic } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { EnMaterialIcon } from '@medlogic/shared/gecore';
import { EnActivityType } from '@medlogic/shared/gecore';
import { IMessage } from '@medlogic/shared/gecore';
import { Base64 } from 'js-base64';
import { UiDialogAlertComponent } from '@medlogic/shared/ui/dialog/ui-dialog-alert';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { FileUploadDialogComponent } from './../../shared/dialog/file-upload-dialog/file-upload-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { EnFileUploadMode } from '../../shared/enum/EnFileUploadMode';
import { IFileUploadDialog } from '../../shared/interface/IFileUploadDialog';
import { IDocumento } from '@medlogic/shared/shared-interfaces';
import { LoadingDialogComponent } from '../../shared/dialog/loading-dialog/loading-dialog.component';
import { IIniciarNova } from '../../shared/interface/iiniciar-nova';
import { IPasso } from '../../shared/interface/ipasso';

/* Atenção: A validação de formulários segue ao modelo ReactiveFormsModule
 * https://angular.io/docs/ts/latest/cookbook/form-validation.html
 * http://blog.angular-university.io/introduction-to-angular-2-forms-template-driven-vs-model-driven/
 * No Reative não deve haver vinculação automática do dado ao modelo.
 * Observe que foi injetado em app.modulo tanto o Reactive quanto o Form convencional
 * é provável que esse processo não seja o ideal e gere sobrecarga.
 * A carga inicial dos dados e a validação de erros são resolvidos nessa classe.
 * A alteração de dados e cascata estão numa subscrição do formGroup em AtividadeComponente.
 */

// Tornou-se um elemento central, uma vez que a chamada para carregamentos dos controles ocorre aqui.
// Dessa classe é que os componentes são propagados para a AtividadeComponent.
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-atividade-view',
  templateUrl: './atividade-view.component.html',
  styleUrls: ['./atividade-view.component.css']
})
export class AtividadeViewComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {

  @Input() processoNo = -1;
  @Input() atividadeNo: number;
  @Input() ocorrenciaNo: number;
  @Input() tarefaNo = -1;
  @Input() defaultFormControls: any;
  @Input() isMobile: boolean;
  @Input() isAndroid: boolean;
  @Input() enTheme = EnTheme.default;
  @Input() canShowSavedMessages = true;
  @Input() saveInList: boolean; // Determina se deve salvar o resultado no cadastro
  @Input() isReadOnly: boolean;
  @Input() printOnly: string[]; // Se preenchido, somente listará para impressão os documentos com o título especificado
  @Input() readOnlyExcept: number[]; // Se preenchido, todos os campos serão somente leitura exceto as variáveis listadas
  @Input() enAtividadeTipo: EnumAtividadeTipo;

  @Output() eventAfterCompleted = new EventEmitter<any>();
  @Output() afterSaved = new EventEmitter<any>();
  @Output() emitBack = new EventEmitter<any>();
  @Output() emitErpRefresh = new EventEmitter<any>();
  @Output() eventBubble = new EventEmitter<IBubble>();
  @Output() valueChanged = new EventEmitter<{ values: { [key: string]: string | Date | number }, changedComponents: IAtividadeComponenteDAL[] }>();

  @ViewChild(TabComponent, { static: true }) viewTabComponent;

  formGroup: FormGroup;
  componentes: IAtividadeComponenteDAL[];
  tabs: IBasic[];
  atividade: IAtividade;
  atividadeNome: string;
  // tarefaNo = -1; // Deve ser definida na Atividade e não em config, pois, a tarefa só faz sentido dentro de uma Atividade.
  actived = false;
  submited = false;
  saved = false;
  tabActivedId = 0;
  // growlMsgs: any[] = [];
  isEditMode: boolean = undefined;

  public set growlMsgs(v: any[]) {
    this.messageService.addAll(v);
  }

  activityType: EnActivityType;
  debugMsg = '';

  isLoadingAfterComplete = false;

  // private _isLoading = true;
  // public get isLoading(): boolean {
  //   return this._isLoading; // Desativado, pois, estava mudando durante o ciclo de carregamento, gerando erro na ActionBar.
  // }
  // public set isLoading(v: boolean) {
  //   this._isLoading = v;
  // }

  isLoading = new BehaviorSubject(true);

  public get isDebug(): boolean {
    return this.cnfJson.isDebug;
  }

  public get cleanPrevState(): boolean {
    return this.activityType !== EnActivityType.ListDetail;
  }

  message: IAlertMessage = {
    firstButtonLabel: 'Não',
    title: 'Confirmação',
    icon: 'fa-times',
    text: '',
    acceptFunc: () => {
      // método de exclusão
    }
  };

  // /* Necessário para passar como parâmetro para o cabeçalho */
  // public get ocorrenciaNo(): number {
  //   return this.config.OcorrenciaNo.value;
  // }

  /* Indica se tentou-se encontrar uma ocorrencia, mas ela não existe */
  protected ocorrenciaNotFound = false;

  formErrors = {};
  protected validationMessages = {};
  protected loadingDialog = null;

  constructor(
    protected global: GlobalService,
    protected config: ConfigStateService,
    protected cnfJson: ConfigJsonService,
    protected route: ActivatedRoute,
    protected log: LogService,
    protected ocorrenciaDAL: OcorrenciaDAL,
    protected atividadeDAL: AtividadeDAL,
    protected fb: FormBuilder,
    protected atividadeComponenteDAL: AtividadeComponenteDAL,
    protected calc: CalculadoraService,
    protected dadoDAL: DadoDAL,
    protected calculator: CalculatorService,
    protected validator: ValidatorService,
    protected action: ActionService,
    protected calcCond: CalculadoraConditionService,
    protected navigation: NavigationService,
    protected lib: LibService,
    protected confirmationService: ConfirmationService,
    protected wsTracker: WsTrackerService,
    protected execucaoTarefa: ExecucaoTarefaDAL,
    protected usuarioDAL: UsuarioDAL,
    protected processoDAL: ProcessoDAL,
    protected matDialog: MatDialog,
    protected msg: MsgPtBR,
    private messageService: MessageService
  ) {
    super();
    this.activityType = EnActivityType.Activity;
    // Necessário para impedir que o formulário tente ser acessado antes dos dados serem construídos
    this.actived = false;
    // TODO: Verificar se não trouxe impacto
    this.wsTracker.cleanCalls();
  }

  /* Atenção: o AtividadeCadastroView redefine o valor de activityType no override desse método.  */
  ngOnInit(): void {
    try {
      // this.isMobile = this.global.isMobile();
      if (this.atividadeNo && this.saveInList) {
        // Atividade fornecida como parâmetro no próprio componente (Input)
        this.getFromInput();
      } else {
        // Extrair o parâmetro de url
        this.getUrlParams();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  // TODO: Não está sendo o melhor modo, pois, em todo refresh de tela e não apenas de dados, chama novamente.
  ngAfterViewInit(): void {
    try {
      // Para avisar os componentes filho que todos os componentes já foram carregados
      if (this.viewTabComponent) {
        this.viewTabComponent.onAllComponentsLoaded();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngAfterViewInit', error.message);
    }
  }
  /* Carrega os parâmetros injetados diretamente no componente. Antes eram passados como parâmetros de url. */
  getFromInput(): any {
    try {
      // if (this.atividadeNo) {
      const ano = +this.atividadeNo;
      // }
      // if (this.processoNo) {
      const pno = +this.processoNo;
      // }
      // if (this.ocorrenciaNo) {
      // this.config.OcorrenciaNo.next(+this.ocorrenciaNo);
      const ono = this.ocorrenciaNo ? +this.ocorrenciaNo : -1;
      // }
      // if (this.readOnly) {
      // this.config.isReadOnly = this.readOnly;
      const isReadOnly = this.isReadOnly;
      // }
      const saveInList = this.saveInList;
      // Será preenchido no refresh
      // this.config.fillActivityParams(
      //   ano,
      //   ono,
      //   null,
      //   isReadOnly,
      //   saveInList,
      //   this.cleanPrevState
      // );
      // if (this.theme) {
      //   this.enTheme = params.theme;
      // }
      // if (this.saveInList) {
      //   this.saveInList = params.saveinlist;
      // }
      // O refresh não pode ser no ngOnInit, pois, quando a rota
      // não muda, mas apenas os parâmetros da rota, esse método não é chamado novamente.
      // Se o parâmetro for atualizado, atualiza o carregamento da tela
      // this.subs.sink = this.refresh(
      //   EnumAtividadeTipo.Editar,
      //   this.config.ModeloAtividadeNo,
      //   this.config.OcorrenciaNo.value,
      //   this.config.usuarioLogadoNo,
      //   this.defaultFormControls || this.config.getDefaultFormControls(this.config.ModeloAtividadeNo),
      //   false,
      //   this.config.processoNo
      // ).subscribe();
      this.subs.sink = this.refresh(
        this.enAtividadeTipo || (ono > 0 ? EnumAtividadeTipo.Editar : EnumAtividadeTipo.Criar),
        ano,
        ono,
        this.config.usuarioLogadoNo,
        this.defaultFormControls || this.config.getDefaultFormControls(ano),
        false,
        pno
      ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromInput', error.message);
    }
  }

  /* Recarrega a tela */
  protected refresh(
    enAtividadeTipo: EnumAtividadeTipo,
    ano: number,
    ono: number,
    uno: number,
    defaultFormControls: any,
    addToHistory: boolean = true,
    pno: number = -1,
    tno: number = -1
  ): Observable<IAtividade> {
    try {
      this.config.fillActivityParams(
        ano,
        ono,
        null,
        pno,
        tno,
        this.isReadOnly,
        this.saveInList,
        this.cleanPrevState
      );
      this.isEditMode = this.isEditMode === undefined ? ono > 0 : this.isEditMode;
      const atv$ = this.atividadeDAL.get(ano);
      const fillCtrl = () => mergeMap((atividade: IAtividade) => {
        this.atividade = atividade;
        return this.fillControls(enAtividadeTipo, ano, ono, uno, defaultFormControls, pno, tno, this.readOnlyExcept)
          .pipe(
            map((ctrl: any) => {
              return atividade;
            })
          );
      });
      const afterLoaded = () => tap((atividade: IAtividade) => {
        this.updateRodapeAndProperties(ono, pno, -1, atividade);
        this.tabActivedId = 0;
      });
      const loadActivityByPno$ = (usuarioNo: number, processoNo: number) =>
        this.atividadeDAL
          .getPrimeiraAtividade(usuarioNo, processoNo)
          .pipe(
            mergeMap((atividade) => {
              return this.atividadeDAL.getAtividadePorAtividadeNoProcessoNo(
                atividade.UsuarioNo,
                atividade.AtividadeNo,
                processoNo
              );
            }
            )
          );
      // Pipe principal
      const activity$ = (pno && pno > 0) ? loadActivityByPno$(uno, pno) : atv$;
      return activity$
        .pipe(
          fillCtrl(),
          afterLoaded(),
          this.error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  protected error = () => catchError((err, obs) => {
    console.log(err);
    this.isLoading.next(false);
    return of(err);
  })

  /* Verifica se a ocorrencia já existe e, caso -1, chama o serviço para criar uma nova e a retorna. */
  protected criarOcorrenciaSeNecessario(ocorrenciaNo: number): Observable<any> {
    try {
      let ocorrencia: Observable<any>;
      if (ocorrenciaNo && ocorrenciaNo <= 0) {
        ocorrencia = this.ocorrenciaDAL.setAll(2, this.config.usuarioLogadoNo);
        return ocorrencia;
      } else {
        // Retorna a própria ocorrencia, uma vez que já existe
        return of(ocorrenciaNo);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'criarOcorrenciaSeNecessario', error.message);
    }
  }

  /* Checa se todas as solicitações ao serviço já foram concluídas. */
  protected trackLoading(ano: number, enableIsLoading: boolean = true): void {
    try {
      if (enableIsLoading) {
        this.isLoading.next(true);
      }
      this.subs.sink = this.wsTracker.callCount().subscribe((s) => {
        // A checagem dos valores default é importante, pois, ela ocorrerá após o carregamento completo dos dados
        try {
          if (!(s > 0 && this.global.IsNullOrEmpty(this.config.getDefaultFormControls(ano, false)))) {
            if (enableIsLoading) {
              this.isLoading.next(false);
            }
          }
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'trackLoading.callCount', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'trackLoading', error.message);
    }
  }

  /* Extrai os parâmetros passados na url/rota */
  protected getUrlParams(): void {
    try {
      // Os parametros estão sendo passados diretamente aos componentes
      this.subs.sink = this.route.params
        .pipe(
          mergeMap((params: Params) => {
            // if (params.key) {
            //   this.config.ModeloAtividadeNo = +params.key;
            // }
            // if (params.listvno) {
            //   this.config.listVno = +params.listvno;
            // }
            // if (params.readonly) {
            //   this.config.isReadOnly = params.readonly === 'true';
            // }
            // if (params.saveinlist) {
            //   this.saveInList = params.saveinlist;
            // }
            if (params.theme) {
              this.enTheme = params.theme;
            }
            const ano = +params.key;
            const pno = params.pno ? +params.pno : -1;
            const ono = params.ono ? +params.ono : -1;
            this.isReadOnly = params.readonly === 'true';
            this.saveInList = params.saveinlist || this.saveInList;
            this.config.processoNo = pno;
            // Nesse momento, ono poder ser -1 ainda
            // this.config.fillActivityParams(
            //   ano,
            //   ono,
            //   null,
            //   isReadOnly,
            //   saveInList,
            //   cleanPrevState
            // );
            // Necessário zerar a ocorrencia se não for fornecida, pois, é um requisito para se criar nova.
            // this.config.OcorrenciaNo.next(params.ono ? +params.ono : -1);
            // O refresh não pode ser no ngOnInit, pois, quando a rota não muda,
            // mas apenas os parâmetros da rota, esse método não é chamado novamente.
            // Se o parâmetro for atualizado, atualiza o carregamento da tela
            // return this.refresh(
            //   EnumAtividadeTipo.Editar,
            //   this.config.ModeloAtividadeNo,
            //   this.config.OcorrenciaNo.value,
            //   this.config.usuarioLogadoNo,
            //   this.defaultFormControls || this.config.getDefaultFormControls(this.config.ModeloAtividadeNo),
            //   true,
            //   this.config.processoNo
            // );
            return this.refresh(
              EnumAtividadeTipo.Editar,
              ano,
              ono,
              this.config.usuarioLogadoNo,
              this.defaultFormControls || this.config.getDefaultFormControls(ano),
              true,
              pno
            );
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /* Busca dados do serviço e preenche a propriedade componentes e também a tabs. */
  protected fillControls(
    atividadeTipo: EnumAtividadeTipo,
    atividadeNo: number,
    ocorrenciaNo: number,
    usuarioLogadoNo: number,
    defaultFormControls: any,
    processoNo?: number,
    tarefaNo?: number,
    readOnlyExcept?: number[]
  ): Observable<IAtividadeComponenteDAL[]> {
    try {
      const afterGet = () => tap(
        (c: IAtividadeComponenteDAL[]) => {
          try {
            this.componentes = this.setDefaultValues(c);
            this.buildForm(this.componentes, readOnlyExcept);
            // Para carregar parâmetros iniciais, que podem ser sido definidos externamente.
            this.loadDefaultValuesFromParentActivity(defaultFormControls, this.componentes, atividadeNo);
            // Deve ser executado depois de buildForm onde a visibilidade dos controles é recalculada
            // neste caso pode associar antes da conclusão, pois, o xml envia todos os elementos numa única interação do observable
            this.tabs = this.atividadeComponenteDAL.nomesAbasDiferentes(this.componentes);
            this.actived = true;
            this.isLoading.next(false);
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'fillControls.getAll', error.message);
          }
        }
      );
      return this.atividadeComponenteDAL.getAll(atividadeTipo, atividadeNo, ocorrenciaNo, usuarioLogadoNo)
        .pipe(
          afterGet(),
          this.error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillControls', error.message);
    }
    return of(null);
  }

  /* Preenche com valores definidos numa tela anterior, por exemplo, o clique num item
   * do grid que leva a edição do respectivo item, ou o botão de duplicar.
   * O valor também pode ter sido passado através de cookie, carregado em config.service
   * O wsTracker é para assegurar que as listas estejam carregadas
   * O ValorTexto dos componentes precisa também ser atualizado com base em defaultFormControls
   * ou alguns valores poderão não serem carregados, especialmente os de grid.*/
  protected loadDefaultValuesFromParentActivity(
    defaultFormControls: any,
    componentes: IAtividadeComponenteDAL[],
    ano: number
  ): void {
    try {
      if (!this.global.IsNullOrEmpty(defaultFormControls)) {
        // É necessário clonar o objeto, pois, a posição de memória para a qual
        // aponta defaultFormControls é modificada de forma assíncrona no instante em que a nova ocorrência é criada
        // no entanto, o retorno de wsTracker precisa dos valores exatos, antes dessa mudança.
        const localDefaultFormControls = this.global.cloneObj(defaultFormControls);
        // Atualiza o valor nos componentes. Precisa rodar antes do updateFormValues, que forçará a carga dos controles visuais.
        // Sem isso, os controles que lidam com as variaveis diretamente funcionam, mas o grid não, por exemplo.
        this.updateComponentesFromFormControls(localDefaultFormControls, componentes, false);
        // TODO: Tentativa de limpar os valores da memória para que o clone de um dado não fique se repetindo indefinidamente.
        this.config.cleanDefault(ano);
        let callCountZero = false;
        // Checa se todas as solicitações ao serviço já foram concluídas
        // Necessário esperar, pois, os controles de lista por exemplo, terão valores modificados após o carregamento.
        this.subs.sink = this.wsTracker.callCount().pipe(takeWhile(() => !callCountZero)).subscribe((s) => {
          if (s <= 0) {
            try {
              callCountZero = true;
              // Não basta atualizar os componentes, deve também ser atualizado
              // o valor do form, mas somente depois de garantido todos os carregamentos.
              this.updateFormValues(localDefaultFormControls, componentes, true); // localDefaultFormControls  this.formGroup.value
            } finally {
              // A limpeza precisa ser assegurada ou pode deixar resíduo em outras edições.
              // limpa o valor default após te-lo lido
              // this.config.cleanDefault();
            }
          }
        });
      }
    } catch (error) {
      this.isLoading.next(false);
      this.log.Registrar(this.constructor.name, 'loadDefaultValues', error.message);
    }
  }

  /*A partir de dados do formulário atualiza o ValorTexto/ValorData dos respectivos componentes.
   * Necessário checar se o valor definido é uma fórmula e, caso afirmativo, evoluir o valor.
   * Também, após atualizar os valores, é necessário atualizar todos os controles que são dependentes
   *  (sejam de fórmula, visibilidade ou edição condicional).
   */
  protected updateComponentesFromFormControls(
    formControls: any,
    componentes: IAtividadeComponenteDAL[],
    updateFormControl: boolean = true): void {
    try {
      componentes.forEach((ctrl) => {
        try {
          const prop = this.lib.getId(ctrl.VariavelNo);
          if (formControls.hasOwnProperty(prop)) {
            // Atualização por AsyncValue para disparar o change dos controles dependentes
            if (!ctrl.AsyncValue) {
              ctrl.AsyncValue = new Subject();
            }
            const typed = this.global.getTypedValue(formControls[prop]);
            // ctrl.AsyncValue.next(typed.value); Se essa linha é ativada o campo de valor é exibido e na sequencia zerado.
            // Se apenas atualizar, o assíncrono não perceberá change. Mas o grid precisa dessa atualização pois o assíncrono não funcionou.
            // TODO: Antes estava false. Tentativa de atualizar a combobox via defaultformcontrol?. Estudar impacto.
            this.lib.setGEValue(typed.value, ctrl, true, this.formGroup, updateFormControl);
          }
        } catch (error) {
          this.log.Registrar(
            this.constructor.name,
            'updateComponentesFromFormControls.forEach',
            error.message
          );
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateComponentesFromFormControls', error.message);
    }
  }

  /* Alguns tipos de controles recebem valor default se estiverem vazio.
   * Date: recebem a data do dia, se vazios.
   * É necessário definir esse valor neste momento, pois a propriedade componentes
   * precisa ter o valor atualizado, pois é esse componente que persiste os valores
   * que serão salvos.
   * Também preencherá as propriedades no config que identificam código, título e ativo de um cadastro.
   */
  protected setDefaultValues(componentes: IAtividadeComponenteDAL[]): IAtividadeComponenteDAL[] {
    try {
      if (componentes && componentes.length > 0) {
        componentes.forEach((ctrl) => {
          try {
            // Preenche o valor default para o controle do tipo Date.
            switch (ctrl.Type.toUpperCase()) {
              case this.lib.CTRDATE: // O Valor padrão para CTRDATE é a data do dia
                if (!this.global.IsDate(ctrl.ValorData)) {
                  this.lib.setGEValue(null, ctrl, true);
                }
                break;
              // case this.lib.CTRTEXTBOXLABELED:
              //   if (ctrl.InputMaskType === 'Date') {
              //     const vlr = this.global.getTypedValue(ctrl.ValorTexto);
              //     ctrl.ValorTexto = this.global.DateToddMMYYYY(vlr.value);
              //     ctrl.Valor = ctrl.ValorTexto;
              //     const clm = this.lib.getId(ctrl.VariavelNo);
              //     const control = this.formGroup.get(clm);
              //     if (control) {
              //       control.setValue(ctrl.ValorTexto, { onlySelf: true });
              //       control.markAsDirty();
              //     }
              //   }
              //   break;
            }
            // Identifica globalmente variáveis obrigatórias num tipo Cadastro.
            if (!this.global.IsNullOrEmpty(ctrl.TypeRegister)) {
              switch (ctrl.TypeRegister) {
                case 1:
                  this.config.listIdVariableNo = ctrl.VariavelNo;
                  break;
                case 2:
                  this.config.listTitleVariableNo = ctrl.VariavelNo;
                  break;
                case 3:
                  this.config.listEnabledVariableNo = ctrl.VariavelNo;
                  break;
              }
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'setDeafultValues.forEach', error.message);
          }
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setDefaultValues', error.message);
    }
    return componentes;
  }

  /* Configuração inicial da validação dos formulários
   * https://angular.io/docs/ts/latest/cookbook/form-validation.html
   * @defaultFormControls: se essa propriedade for diferente de null, os valores iniciais serão preenchidos com base nessa lista.
   */
  protected buildForm(componentes: IAtividadeComponenteDAL[], readOnlyExcept: number[] = null): void {
    try {
      const controlsConfig = this.getControlConfig(componentes, readOnlyExcept);
      this.formGroup = this.fb.group(controlsConfig);
      this.formErrors = this.validator.getFormErrors(componentes);
      this.validationMessages = this.validator.getValidationMessages(componentes);
      this.subs.sink = this.formGroup.valueChanges.subscribe((values) => {
        try {
          this.onValueChanged(); // (re)set validation messages now
          this.emitChangedValues(values, this.componentes);
        } catch (error) {
          this.log.Registrar(this.constructor.name, '', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'buildForm', error.message);
    }
  }

  protected emitChangedValues(changedValues: { [key: string]: string | Date | number }, allComponents: IAtividadeComponenteDAL[]): void {
    try {
      const changedKeys = Object.keys(changedValues).filter(key => changedValues[key] && this.formGroup.get(key).dirty && this.formGroup.get(key).touched);
      const changedComps = allComponents.filter(f => changedKeys?.includes(`V_${f.VariavelNo}`));
      if (changedComps?.length > 0) {
        this.valueChanged.emit({ values: changedValues, changedComponents: changedComps });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'emitChangedValues', error.message);
    }
  }

  /* Atualiza todos os valores do formulário, baseado num objeto que contém os ids que se deseja atualizar.
  * objFormControls: tem o formato { "V_2023": "valor", "V_3343": "valor" }.
  * Modifica item por item, para que o change seja sequencial.
  * emitEvent: indica se deve ser disparado um evento de change para cada valor alterado.
  */
  protected updateFormValues(formControl: any, componentes: IAtividadeComponenteDAL[], emitEvent: boolean = true): void {
    try {
      if (!this.formGroup) {
        return;
      }
      const objFormControls = this.global.cloneObj(formControl);
      const obj = {};
      for (const clm in objFormControls) {
        if (clm) {
          try {
            let typed;
            if (Array.isArray(objFormControls[clm])) {
              // Teste necessário, pois o retorno do xml poderá ter como padrão sempre criar um array
              typed = this.global.getTypedValue(objFormControls[clm][0]);
            } else {
              typed = this.global.getTypedValue(objFormControls[clm]);
            }
            // Esse procedimento é necessário para que os campos de texto com máscara de data, se receberem
            // valores por defaultFormControl, não sejam preenchidos com data e hora.
            const ctrl = componentes?.find(f => f.VariavelNo === this.lib.getVariavelNoFromId(clm));
            if (ctrl?.InputMaskType === 'Date') {
              typed.string = this.global.dateToddMMYYYY(typed.value);
            }
            // *** TODO: O fato de não armazenar as datas como datas foi para resolver um
            // problema com as máscaras de data que requerem entradas de texto. No entanto
            // isso pode fazer as fórmulas baseadas em datas parar.
            // if (typed.type === EnTypedValue.Date) {
            //   obj[clm] = typed.value || "";
            // } else {
            obj[clm] = typed.string || '';
            // }
            // ****
            // Desnecessário devido a nova forma de identificar os valores alterados em valuesChanged.
            const control = this.formGroup.get(clm);
            if (control) {
              // Necessário marcar como dirty para que o change dispare os recálculos de fórmulas
              control?.markAsDirty();
            }
          } catch (e) {
            this.log.Registrar(this.constructor.name, 'updateFormValues.for', e.message);
          }
        }
      }
      this.formGroup.patchValue(obj, { onlySelf: true, emitEvent });
      this.formGroup.updateValueAndValidity();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateFormValues', error.message);
    }
  }

  /*Retorna um item de configuração que inclui os validadores, o valor inicial e visibilidade dos componentes.
   * Também já calcula a visibilidade condicional dos componentes, atualizando o objeto.
   * Ordena os componentes pelo TabIndex, o que pode ser importante para, por exemplo, determinar sequencia de calculo de fórmulas.
   * readOnlyExcept: Se preenchido, tornará todos os campos somente leitura, exceto os que tiverem variavelNo listada.
   */
  protected getControlConfig(componentes: IAtividadeComponenteDAL[], readOnlyExcept: number[] = null): any {
    const controlsConfig = {};
    try {
      componentes
        .sort((a, b) => (a.TabOrder ? a.TabOrder : 0) - (b.TabOrder ? b.TabOrder : 1))
        .forEach((ctrl) => {
          try {
            const validators = this.validator.getValidators(ctrl).map<ValidatorFn>((m) => m.validator);
            // Recalcula a vibilidade
            ctrl.IsVisible = this.calcCond.isVisibleCtrl(ctrl);
            // Recalcula o readOnly. forceAllControlsEditable deve ser utilizado apenas para fim de debug.
            ctrl.IsEnable = this.calcIsEnable(ctrl, readOnlyExcept);
            // Assegurar que a propriedade ValorTexto seja preenchida, pois, somente valorDefault ou fórmula poderão estar preenchidos
            ctrl.ValorTexto = this.getValorInicial(ctrl);
            ctrl.Valor = ctrl.ValorTexto;
            // Por padrão do Angular2, para informar disabled=false é necessário criar um objeto com o value
            // mas esse formato não pode ser usado para disable = false
            const objValue = ctrl.IsEnable
              ? ctrl.ValorTexto
              : {
                value: ctrl.ValorTexto,
                disabled: true
              };
            controlsConfig[this.lib.getId(ctrl.VariavelNo)] = [objValue, validators];
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'getControlConfig.forEach', error.message);
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getControlConfig', error.message);
    }
    return controlsConfig;
  }

  protected calcIsEnable(ctrl: IAtividadeComponenteDAL, readOnlyExcept: number[]): boolean {
    try {
      if (this.cnfJson.forceAllControlsEditable) {
        return true;
      }
      const hasReadOnlyExcept = (readOnlyExcept && readOnlyExcept.length > 0);
      return hasReadOnlyExcept ?
        readOnlyExcept.includes(ctrl.VariavelNo) :
        (!this.calcCond.isReadOnly(ctrl) && !this.config.isReadOnly);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calcIsEnable', error.message);
    }
    return false;
  }

  /* Método necessário para extrair o valor inicial correto do controle que considera valor default, processa fórmula, etc.
   * ATENÇÃO: Em relação ao cálculo da fórmula, irá calcular apenas as fórmulas que não dependam de outros controles.
   * as que dependem, serão processadas em AtividadeComponent.onFormControlChanged
  */
  protected getValorInicial(ctrl: IAtividadeComponenteDAL): string {
    try {
      const valorAtual = this.lib.getValorTextoOrData(ctrl);
      // Se o componente for fórmula, mas se o valorAtual estiver preenchido, desconsiderar a fórmula.
      // esse cenário ocorre se o campo é gravado em outra atividade, externamente, ou se o campo é de fórmula mas editável.
      // O outro cenário é se, na fórmula de Edição Condicional, há a fórmula ALWAYSUPDATE() pois isso marcará o campo isAlwaysUpdate
      // para true e o campo deverá ser atualizado, independente do cenário.
      if (ctrl.isAlwaysUpdate || (
        this.global.IsNullOrEmptyGE(valorAtual) &&
        this.lib.isFormula(ctrl.ValorDefault) &&
        ctrl.lstControlesReferenciadosPorFormula === null
      )) {
        // Processar apenas fórmulas que não dependam de outros controles.
        // Para as demais, cálculo em AtividadeComponent.onFormControlChanged.
        return this.calc.calculate(ctrl, null, ctrl.ValorDefault);
      } else {
        return valorAtual || ''; // para evitar valor null
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValorInicial', error.message);
    }
    return '';
  }

  /* Cria o objeto com erros que é disponibilizado no formulário */
  onValueChanged(): void {
    try {
      if (!this.formGroup) {
        return;
      }
      const form = this.formGroup;
      for (const field in this.formErrors) {
        if (field) {
          // clear previous error message (if any)
          this.formErrors[field] = '';
          const control = form.get(field);
          if (control && control?.dirty && !control?.valid) {
            const messages = this.validationMessages[field];
            messages.forEach((m) => {
              for (const key in control?.errors) {
                if (key) {
                  this.formErrors[field] += m[key] + ' ';
                }
              }
            });
          }
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onValueChanged', error.message);
    }
  }

  /* Chamado quando o botão SALVAR da ActionBar for acionado */
  onSubmit(): void {
    try {
      this.subs.sink = this.salvar(
        EnBubbleEvent.activitySave,
        this.componentes,
        this.tarefaNo,
        this.ocorrenciaNo,
        this.config.usuarioLogadoNo,
        this.config.processoNo
      ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSubmit', error.message);
    }
  }

  /* Semelhante ao salvar, mas retorna para a página anterior.
  * Pode ser sobrescrito.
  */
  concluir(
    enBubbleEvent: EnBubbleEvent,
    componentes: IAtividadeComponenteDAL[],
    tarefaNo: number,
    ocorrenciaNo: number,
    usuarioNo: number,
    processoNo: number,
    atividadeNo: number,
    backAfterComplete: boolean = true
  ): Observable<{ passo: IPasso, ocorrencia: IOcorrencia }> {
    try {
      this.openLoading();
      const beforeComplete$ = () => mergeMap((ocorrencia) => {
        this.saved = true;
        return this.beforeComplete(
          enBubbleEvent,
          tarefaNo,
          ocorrenciaNo,
          usuarioNo,
          processoNo,
          atividadeNo,
          componentes,
          this.formGroup
        );
      });
      const afterComplete$ = () => mergeMap((ocorrencia: IOcorrencia) => {
        if (ocorrencia) {
          return this.afterComplete(ocorrencia).pipe(map(ocorrencia => ({ passo: null, ocorrencia })));
        }
        return of(ocorrencia);
      });
      // Lógica transferida para o afterComplete
      // const backAndEmit$ = () => map((ocorrencia: IOcorrencia) => {
      //   if (this.isValidForm() && ocorrencia) {
      //     this.afterCompleted.emit(ocorrencia);
      //     if (backAfterComplete) {
      //       this.historyBack(1);
      //     }
      //   }
      //   return ocorrencia;
      // });
      // Pipe principal
      return this.salvar(enBubbleEvent, componentes, tarefaNo, ocorrenciaNo, usuarioNo, processoNo)
        .pipe(
          beforeComplete$(),
          afterComplete$(),
          // backAndEmit$(),
          tap(() => this.isLoading.next(false)),
          this.error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'concluir', error.message);
    }
    return of(null);
  }

  /**  */
  protected isValidForm(): boolean {
    try {
      return this.formGroup.valid || this.cnfJson.disableRequiredValidation;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isValidForm', error.message);
    }
    return true;
  }

  /**  */
  protected openLoading(): void {
    try {
      this.isLoading.next(true);
      const width = '100vw';
      const height = '100vh';
      this.loadingDialog = this.matDialog.
        open(LoadingDialogComponent,
          {
            width,
            height,
            id: 'loading-dialog',
            minWidth: `width`,
            maxWidth: `width`,
            minHeight: `height`,
            maxHeight: `height`,
            hasBackdrop: true,
            backdropClass: 'backdrop',
            panelClass: 'panel',
            data: {}
          });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'openLoading', error.message);
    }
  }

  protected closeLoading(): void {
    try {
      this.isLoading.next(false);
      if (this.loadingDialog) {
        this.loadingDialog.close();
        this.loadingDialog = null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'closeLoading', error.message);
    }
  }

  /* Salva todos os dados, a partir dos componentes.
   * Outra maneira de fazer seria resgatar os valores de formGroup.
   * Apesar de disparar as validações, salvará mesmo se houver campos pendentes, pois
   * as ações de grid mudam de página e os dados precisam ser salvos para o retorno.
   * ATENÇÃO: Sempre que o dado for salvo é necessário limpar o cache de carregamento do mesmo dado, caso haja.
   * Caso contrário, na tentativa de recarregar o dado por uma segunda vez, trará os dados da primeira.
   * processoNo poderá ser nulo uma vez que os cadastros não são associados a processos.
   */
  salvar(
    enBubbleEvent: EnBubbleEvent,
    componentes: IAtividadeComponenteDAL[],
    tarefaNo: number,
    ocorrenciaNo: number,
    usuarioNo: number,
    processoNo: number
  ): Observable<IOcorrencia> {
    try {
      this.isLoading.next(true);
      if (this.formGroup.invalid) {
        this.showInvalidFormMessage();
      }
      // return this.action.salvar(componentes, tarefaNo, ocorrenciaNo, usuarioNo, processoNo)
      // FIXME: Esse método abaixo não deveria ser apenas no AtividadeProcessoComponente?
      return this.action.criarOcorrenciaESalvar(componentes, tarefaNo, ocorrenciaNo, usuarioNo, processoNo)
        .pipe(
          mergeMap((result: IIniciarNova) => {
            try {
              if (result) {
                this.saved = true;
                // NÃO deve ser atualizado esse item aqui, pois, pode ser uma transição do salvar a principal para abrir a Detalhe.
                // this.updateRodapeAndProperties(
                //   result.OcorrenciaNo,
                //   processoNo,
                //   result.TarefaNo,
                //   this.atividade
                // );
                const ano = result.AtividadeNo; // componentes[0].AtividadeNo;
                this.atividadeComponenteDAL.cleanCacheGetComponentesEditData(ano, ocorrenciaNo, usuarioNo);
                this.isLoading.next(false);
                return this.afterSave(
                  enBubbleEvent,
                  result.TarefaNo,
                  ano,
                  ocorrenciaNo,
                  usuarioNo,
                  processoNo,
                  this.componentes,
                  this.formGroup,
                  this.isEditMode
                );
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'salvar.salvar', error.message);
              this.isLoading.next(false);
            }
            return of(null);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'salvar', error.message);
    }
    return of(null);
  }

  /* Evento chamado após a o salvamento da atividade. Poderá ser sobrescrito.
   */
  protected afterSave(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[],
    fg: FormGroup,
    isEditMode: boolean
  ): Observable<IOcorrencia> {
    try {
      this.updateChangedFormControl(tno, ano, ono, uno, pno, componentes, 'SAVE');
      this.isLoading.next(false);
      const res = { OcorrenciaNo: ono, TarefaNo: tno, AtividadeNo: ano, ProcessoNo: pno, Componentes: componentes, fg, isEditMode } as IOcorrencia;
      this.afterSaved.emit(res);
      return of(res);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSave', error.message);
    }
    return of(null);
  }

  protected afterComplete(
    ocorrencia: IOcorrencia
  ): Observable<IOcorrencia> {
    try {
      const ifCompleted$ = () => mergeMap((o: IOcorrencia) => {
        this.isLoading.next(false);
        return !this.formGroup.invalid && (o && o.OcorrenciaNo > 0) ?
          this.doActivityComplete(o.TarefaNo, o.AtividadeNo, o.OcorrenciaNo, this.config.usuarioLogadoNo, o.ProcessoNo) :
          of({ passo: null, ocorrencia: o });
      });
      const backAndEmit$ = () => map((res: { passo?: IPasso, ocorrencia: IOcorrencia }) => {
        if (this.isValidForm() && res?.ocorrencia) {
          const { TarefaNo, AtividadeNo, OcorrenciaNo, ProcessoNo } = res?.ocorrencia;
          this.emitAfterCompleted(TarefaNo, AtividadeNo, OcorrenciaNo, this.config.usuarioLogadoNo, ProcessoNo);
          // this.afterCompleted.emit(o);
        }
        return res?.ocorrencia;
      });
      return of(ocorrencia).pipe(ifCompleted$(), backAndEmit$());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterComplete', error.message);
    }
    return of(null);
  }


  /* FIXME: conceitualmente esse método não deveria existir aqui que é Atividade Cadastro e não processo
 * Realiza a conclusão da Atividade.
 * Nesse componente, deverá emitir um evento a ser capturado por quem utilizar esse componente. */
  protected doActivityComplete(tno: number, ano: number, ono: number, uno: number, pno: number): Observable<{ passo: IPasso, ocorrencia: IOcorrencia }> {
    try {
      this.isLoadingAfterComplete = false;
      this.isLoading.next(false);
      return this.ocorrenciaDAL
        .setOcorrenciaNotificarConclusaoSalvamento(pno, ono, uno, ano)
        .pipe(
          mergeMap((result) => {
            // TODO: checar porque Variaveis de identificação não estão sendo preenchidas
            const varId1 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao1No}`);
            const varId2 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao2No}`);
            this.atividade.CalcVariavelIdentificacao1Valor = varId1 ? varId1.value : -1;
            this.atividade.CalcVariavelIdentificacao2Valor = varId2 ? varId2.value : -1;
            this.atividade.OcorrenciaNo = ono;
            this.atividade.TarefaNo = tno;
            return this.processoDAL
              .getProximoPasso(this.atividade, pno, uno, tno, ono)
              .pipe(
                map((passo) => {
                  // FIXME: Idealmente, essa deveria ser uma chamada assíncrona, retornando também observable
                  // this.executeNextStep(this.atividade, proximoPasso);
                  this.isLoadingAfterComplete = false;
                  // Faria com que fosse chamado afterCompleted duas vezes, uma vez que é chamado no afterComplete.
                  // e, nesse, ponto, como a return após, não funcionou
                  // this.emitAfterCompleted(tno, ano, ono, uno, pno);
                  const ocorrencia = {
                    AtividadeNo: ano,
                    OcorrenciaNo: ono,
                    TarefaNo: tno,
                    ProcessoNo: pno,
                    Componentes: this.componentes,
                    fg: this.formGroup,
                    isEditMode: this.isEditMode
                  } as IOcorrencia;
                  return { passo, ocorrencia };
                })
              );
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'doActivityComplete', error.message);
    }
    return of(null);
  }

  // FIXME: ATENÇÃO MONITORAR O RISCO DE IMPACTO NO FLUXO DE CONCLUSÃO E/OU DUPLICAÇÃO DE TAREFAS
  protected emitAfterCompleted(tno: number, ano: number, ono: number, uno: number, pno: number): void {
    try {
      if (this.isValidForm()) {
        const obj = {
          values: this.formGroup.getRawValue(),
          tno,
          ano,
          ono,
          uno,
          pno
        };
        this.eventAfterCompleted.emit(obj);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'emitAfterCompleted', error.message);
    }
  }

  /* Evento chamado antes da conclusão. Validará erros no formulário e poderá cancelar a continuidade.
   */
  protected beforeComplete(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ono: number,
    uno: number,
    pno: number,
    ano: number,
    componentes: IAtividadeComponenteDAL[],
    formGroup: FormGroup
  ): Observable<IOcorrencia> {
    const result = { AtividadeNo: ano, OcorrenciaNo: ono, TarefaNo: tno, ProcessoNo: pno, Componentes: componentes } as IOcorrencia;
    try {
      // Desativação dessa mensagem, pois, já é checado no salvar.
      this.closeLoading();
      // return of(this.cnfJson.disableRequiredValidation ? result : null);
      // if (formGroup.invalid) {
      //   const width = '450px';
      //   const height = '70vh';
      //   const invalid = this.getInvalidComponents(formGroup, componentes);
      //   const dialogRef = this.matDialog
      //     .open(
      //       WindowDialogComponent, {
      //       width,
      //       height,
      //       minWidth: width,
      //       minHeight: height,
      //       data: {
      //         title: this.msg.DIALOG_TITLE_FORM_INVALID,
      //         messageHtml: `Os seguintes controles estão inválidos: ${invalid}`
      //       }
      //     });
      //   return dialogRef.afterClosed()
      //     .pipe(map(res => {
      //       return this.cnfJson.disableRequiredValidation ? result : null;
      //     })
      //     );
      // }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'beforeComplete', error.message);
    }
    return of(result);
  }

  protected getInvalidComponents(formGroup: FormGroup, componentes: IAtividadeComponenteDAL[]): string {
    try {
      const invalidKeys = Object.keys(formGroup.controls).filter(f => formGroup.controls[f].invalid);
      return formGroup.invalid ?
        componentes?.filter(f => invalidKeys.includes(this.lib.getId(f.VariavelNo)))
          .map(m => m.Rotulo)
          .join(', ')
          .replace(/(:|\*)/g, '')
        : '';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getInvalidComponents', error.message);
    }
    return null;
  }

  /* Evento chamado após a exclusão do item. Poderá ser sobrescrito.
   */
  protected afterDelete(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[]
  ): void {
    try {
      this.updateChangedFormControl(tno, ano, ono, uno, pno, componentes, 'DELETE');
      this.isLoading.next(false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSave', error.message);
    }
  }

  /*
  * A operação básica será de popular um objeto no config que permitirá saber qual foi o item
  * recentemente alterado e quais os novos valores. Isso possibilitará, por exemplo,
  * atualizar um grid no retorno.
  */
  protected updateChangedFormControl(
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[],
    action: string
  ): void {
    try {
      const gridCtrl = componentes.find((f) => this.global.isEqual(f.Type, this.lib.CTRGRID));
      let obj = {} as any;
      // tslint:disable: curly
      if (action === 'SAVE') obj = this.lib.mapComponentesToFormControl(componentes);
      if (action) obj.action = action;
      if (gridCtrl) obj.gridCtrl = gridCtrl.lstCadastroAdicional;
      if (tno) obj.TarefaNo = tno;
      if (ano) obj.AtividadeNo = ano;
      if (ono) obj.OcorrenciaNo = ono;
      if (uno) obj.UsuarioLogadoNo = uno;
      if (pno) obj.ProcessoNo = pno;
      this.config.setChangedFormControl(ano, ono, obj);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateChangedFormControl', error.message);
    }
  }

  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  onEventBubble(bubble: IBubble): void {
    try {
      switch (bubble.bubbleEvent) {
        case EnBubbleEvent.listEdit:
          this.listDetailEdit(
            bubble,
            bubble.params.ano,
            bubble.params.ono,
            bubble.params.listvno,
            bubble.params.isReadOnly,
            bubble.params.saveInList
          );
          break;
        case EnBubbleEvent.listNew:
          this.listDetailNew(bubble.params.ano, bubble.params.listvno, bubble.params.saveInList);
          break;
        case EnBubbleEvent.listSaveAndNew:
          this.listDetailSaveAndNew(
            bubble.params.ano,
            bubble.params.saveInList,
            bubble.params.addToHistory,
            false
          );
          break;
        case EnBubbleEvent.listDuplicate:
          this.listDetailSaveAndNew(
            bubble.params.ano,
            bubble.params.saveInList,
            bubble.params.addToHistory,
            true
          );
          break;
        case EnBubbleEvent.listDelete:
          this.listDelete(
            bubble,
            bubble.params.ano,
            bubble.params.ono,
            this.tarefaNo,
            this.config.usuarioLogadoNo,
            bubble.params.pno || -1,
            this.componentes
          );
          break;
        case EnBubbleEvent.isLoading:
          this.isLoading.next(true);
          break;
        case EnBubbleEvent.historyBack:
          let position = 1;
          if (bubble && bubble.params && bubble.params.position) {
            position = bubble.params.position;
          }
          // TODO: Necessário limpar a ocorrencia para evitar reaproveitamento incorreto
          this.historyBack(position);
          break;
        case EnBubbleEvent.historyRemoveLast:
          this.historyRemoveLast();
          break;
        case EnBubbleEvent.activityComplete:
          this.isLoading.next(true);
          this.subs.sink = this.concluir(
            bubble.bubbleEvent,
            this.componentes,
            this.tarefaNo,
            this.config.OcorrenciaNo.value,
            this.config.usuarioLogadoNo,
            this.config.processoNo,
            this.atividadeNo,
            bubble.params.backAfterComplete
          ).subscribe();
          break;
        case EnBubbleEvent.activitySave:
          this.subs.sink = this.salvar(
            bubble.bubbleEvent,
            this.componentes,
            this.tarefaNo,
            this.config.OcorrenciaNo.value,
            this.config.usuarioLogadoNo,
            this.config.processoNo
          ).subscribe();
          break;
        case EnBubbleEvent.activityDelete:
          // Não implementado. A exclusão de uma Atividade deve ser apenas um cancelamento da Tarefa.
          break;
        case EnBubbleEvent.alertDialog:
          this.subs.sink = this.onAlertDialog(bubble.params.message, bubble.params.hasConfirmButton).subscribe();
          break;
        case EnBubbleEvent.windowDialog:
          this.onWindowDialog(
            bubble.params.title,
            bubble.params.message,
            bubble.params.width,
            bubble.params.icon || ''
          );
          break;
        case EnBubbleEvent.openAttachments:
          this.onOpenAttachments(
            bubble.params.url,
            bubble.params.ono,
            bubble.params.ano,
            bubble.params.tno,
            bubble.params.vno,
            bubble.params.width,
            bubble.params.height,
            bubble.params.ctrl,
            bubble.params.enFileUploadMode
          );
          break;
        // ERP
        case EnBubbleEvent.gotoErpGrip:
          this.gotoErpGrid();
          break;
        case EnBubbleEvent.ErpRefresh:
          this.erpRefresh();
          break;
        case EnBubbleEvent.debug:
          this.showDebug(bubble.params.msg);
          break;
        case EnBubbleEvent.componentesChanged:
          if (bubble.params && bubble.params.componentes) {
            this.componentes = bubble.params.componentes;
          }
          break;
        case EnBubbleEvent.afterSavedMessage:
          // Necessário para que a próxima chamada do saved mostre a mensagem novamente
          // A temporização evita o ExpressionChangedAfterItHasBeenCheckedError, pois o
          // saved = true ocorre logo antes, no salvar, antes da página ter sido desenhada.
          setTimeout(() => {
            this.saved = false;
            this.isLoading.next(false);
          }, 1000);
          break;
        case EnBubbleEvent.afterPrint:
          break;
      }
      this.eventBubble.emit(bubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onEventBubble', error.message);
    }
  }

  /* New implementation. It didn't depend of GE MVC any more.
   * But it does depends of the python fileupload api.
   */
  protected onOpenAttachments(
    url: string,
    ono: number,
    ano: number,
    tno: number,
    vno: number,
    width: string,
    height: string,
    ctrl?: IAtividadeComponenteDAL,
    enFileUploadMode: EnFileUploadMode = EnFileUploadMode.list
  ): void {
    try {
      const uno = this.config.usuarioLogadoNo;
      this.subs.sink = this.matDialog
        .open(FileUploadDialogComponent,
          {
            minWidth: width,
            minHeight: height,
            maxWidth: width,
            maxHeight: height,
            width,
            height,
            data: { url, ono, tno, ano, uno, vno, enFileUploadMode } as IFileUploadDialog
          })
        .afterClosed()
        .subscribe((doc: IDocumento) => {
          if (ctrl && doc && doc?.CaminhoFisico !== null) { // TODO: Is it better to deal with this at ctr-image-player?
            this.eventImgReturn(ctrl, doc?.Url);
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onOpenAttachments', error.message);
    }
  }

  /* Set the image value from a ctrl, after upload. */
  protected eventImgReturn(ctrl: IAtividadeComponenteDAL, filePath: string): void {
    try {
      ctrl.Rotulo = filePath;
      ctrl.ValorTexto = filePath;
      const ctr = this.formGroup.get(this.lib.getId(ctrl.VariavelNo));
      ctr.setValue(ctrl.ValorTexto);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'eventImgReturn', error.message);
    }
  }

  /* Exibirá a mensagem de debug na janela lateral.
   * Mas está condicionado a sinalização positiva no config.json.
   */
  protected showDebug(msg: string): void {
    try {
      this.debugMsg += `<br />${msg}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showDebug', error.message);
    }
  }

  /* Retorna para a página anterior conforme o histórico. */
  protected historyBack(position: number): void {
    try {
      this.navigation.goBack(position);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'historyBack', error.message);
    }
  }

  /* Remove a última (mais recente) posição do histórico.
   * Útil quando se deseja avaçar para uma página cujo retorno deve retornar ao ponto
   * que chamou a última posição.
  */
  protected historyRemoveLast(): void {
    try {
      this.navigation.removeLastHistory();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'historyRemoveLast', error.message);
    }
  }

  /* OVERRIDE EM ATIVIDADE-CADASTRO-VIEW */
  /* Exclui um item do cadastro */
  protected listDelete(
    $event: any,
    ano: number,
    ono: number,
    tno: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[]
  ): void { }

  /* Roteia para a edição de um Cadastro. Salva os dados da Atividade atual antes.
   */
  protected listDetailEdit(
    $event: any,
    ano: number,
    ono: number,
    listvno: number,
    isReadOnly: boolean,
    saveInList: boolean
  ): void { }

  /* Roteia para a criação de um novo item do tipo Cadastro. Salva os dados da Atividade atual antes. */
  protected listDetailNew(ano: number, listvno: number, saveInList: boolean): void { }

  /* Além de salvar, promoverá um refresh da mesma atividade e cria nova ocorrencia. */
  protected listDetailSaveAndNew(
    ano: number,
    saveInList: boolean,
    addToHistory: boolean = true,
    preserveValues: boolean = true
  ): void { }

  /* FIM OVERRIDE EM ATIVIDADE-CADASTRO-VIEW */

  /* Exibe uma mensagem com estilo de notificação. */
  protected showGrowlNotification(message: IMessage): void {
    try {
      this.growlMsgs = [{ severity: message.severity, summary: message.summary, detail: message.detail }];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showGrowlNotification', error.message);
    }
  }

  /* Esconde uma mensagem com estilo de notificação. */
  protected hideGrowlNotification(): void {
    try {
      this.messageService.clear();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showGrowlNotification', error.message);
    }
  }

  /* Exibe uma mensagem de Alerta/Confirmação, com um ou dois botões aguardando confirmação */
  protected onAlertDialog(message: IAlertMessage, hasConfirmButton: boolean = false): Observable<any> {
    try {
      let dialogRef = null;
      const width = '100vw';
      const height = '350px';
      const options = {
        width,
        height,
        id: 'rpt-alert',
        maxWidth: '520px',
        minHeight: height,
        maxHeight: height,
        hasBackdrop: true,
        backdropClass: 'backdrop',
        panelClass: 'panel',
      };
      if (!hasConfirmButton) {
        dialogRef = this.matDialog
          .open(UiDialogAlertComponent, {
            ...options,
            data: {
              title: message.title,
              messageHtml: message.text,
              icon: EnMaterialIcon.check,
              btnOK: 'OK'
            }
          });
      } else {
        dialogRef = this.matDialog
          .open(DialogConfirmComponent, {
            ...options,
            data: {
              title: message.text,
            }
          });
      }
      if (message.acceptFunc) {
        this.subs.sink = dialogRef.afterClosed().subscribe(result => message.acceptFunc(result));
      }
      return dialogRef.afterClosed()
        .pipe(
          map((m) => true)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAlertDialog', error.message);
    }
  }


  /*Exibe uma janela com conteúdo html.
   * width deve conter a unidade, por exemplo 100px ou 100%.
   */
  protected onWindowDialog(
    title: string,
    messageHtml: string,
    width: string,
    icon: EnMaterialIcon = EnMaterialIcon.warning
  ): MatDialogRef<WindowDialogComponent, any> {
    try {
      messageHtml = this.extractHtmlBody(messageHtml); // Live NodeList of your anchor elements
      const dialogRef = this.matDialog.open(WindowDialogComponent, {
        width: `${width}`,
        data: { title, messageHtml, icon }
      });
      return dialogRef;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onWindowDialog', error.message);
    }
    return null;
  }

  /* Se for um grid, irá atualizar o valor da propriedade vinculada ao item do grid que está sendo editado.
   * TODO: Numa segunda reflexão, talvez o ideal fosse pegar uma única vez os valores do grid em cenário de criação ou edição de item,
   * ao invés de ficar monitorando todas as modificações.
   */
  // protected subscribeToChangesIfIsGrid(ano: number): void {
  // 	try {
  // 		const objGrid = this.config.getGridItems(ano, false);
  // 		if (objGrid) {
  // 			// Somente a edição/novo de um grid é que criam essa propriedade
  // 			if (this.formGroup) {
  // 				this.formGroup.valueChanges.subscribe(() => {
  // 					try {
  // 						const gridItem = !objGrid
  // 							? {}
  // 							: objGrid.find((f) => f.index === this.config.OcorrenciaNo.value || f.index === -1); // Não usar === pois index pode ser string
  // 						if (gridItem) {
  // 							const allProperties = this.formGroup.getRawValue(); // Todas as propriedades independente se o valor foi alterado.
  // 							for (const clm in allProperties) {
  // 								if (clm) {
  // 									gridItem[clm] = this.global.getTypedValue(allProperties[clm]).string;
  // 								}
  // 							}
  // 							gridItem.index = this.config.OcorrenciaNo.value;
  //             }
  //             this.config.setGridItem(ano, gridItem);
  // 					} catch (error) {
  // 						this.log.Registrar(
  // 							this.constructor.name,
  // 							'subscribeToChangesIfIsGrid.valueChanges',
  // 							error.message
  // 						);
  // 					}
  // 				});
  //       }
  // 		}
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'subscribeToChangesIfIsGrid', error.message);
  // 	}
  // }

  /* SOBRESCREVER!
   * Identico ao subscribeToChangesIfIsGrid, mas atualiza o item da combobox.
   * Era utilizada a mesma variável, mas a lógica de um estava atrapalhando a do outro.
   */
  protected subscribeToChangesIfIsList(): void {
    try {
      // if (this.config.listItem) {
      //   if (this.formGroup) {
      //     this.formGroup.valueChanges.subscribe(s => {
      //       try {
      //         if (this.config.listItem) { // Necessário revalidar, pois, uma vez inscrito será chamado novamente
      //           this.config.listItem["index"] = this.config.OcorrenciaNo.value;
      // modificado de this.ocorrenciaNo para tentar resgatar esse parâmetro
      //           for (let clm in s) {
      //             this.config.listItem[clm] = this.global.getTypedValue(s[clm]).string;
      //           }
      //         }
      //       } catch (error) {
      //         this.log.Registrar(this.constructor.name, 'subscribeToChangesIfIsList.valueChanges', error.message);
      //       }
      //     });
      //   }
      // }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'subscribeToChangesIfIsList', error.message);
    }
  }

  /* Retorna a exibição para o ERP Grid. Na verdade, fará o mesmo comportamento
  * do botão back, que irá ocultar o componente da atividade e exibir o do Grid.
   * Como esse é um componente interno, deverá propagar a comunicação com o ERP através de um evento.
  */
  protected gotoErpGrid(): void {
    try {
      this.emitBack.emit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'gotoErpGrid', error.message);
    }
  }

  /* Promove o recarregamento dos dados do ERP, sem levar até a tela de Grid */
  protected erpRefresh(): void {
    try {
      this.emitErpRefresh.emit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'erpRefresh', error.message);
    }
  }

  /* Extrair o body de um documento html.
   * Também aplica estilos, caso existam.
   * É necessário para compatibilizar o conteúdo formatado no Studio,
   * na mensagem de confirmação de uma Atividade com formulário web ativado.
   * Além disso, se houver link, injetará os parametros o=ocorrenciaNo&p=processoNo
   */
  protected extractHtmlBody(messageHtml: string): string {
    try {
      const el = document.createElement('html');
      el.innerHTML = messageHtml;
      const body = el.getElementsByTagName('body');
      if (body && body.length > 0) {
        messageHtml = body[0].innerHTML;
      }
      // #region injetar os estilos no html, caso existam
      const css = el.getElementsByTagName('style');
      if (css && css.length > 0) {
        const head = document.head || document.getElementsByTagName('head')[0];
        const newStyle = document.createElement('style');
        // tslint:disable-next-line: deprecation
        newStyle.type = 'text/css';
        newStyle.innerText = css[0].innerText;
        head.appendChild(newStyle);
      }
      // #endregion
      // #region injetar os parâmetros no link, caso haja
      const link = el.getElementsByTagName('a');
      if (link && link.length > 0) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < link.length; i++) {
          const l = link[i];
          const separator = l.href.indexOf('?') >= 0 ? '&' : '?';
          const ono = Base64.encode(this.config.OcorrenciaNo.value.toString());
          const pno = Base64.encode(this.config.processoNo.toString());
          const ano = Base64.encode(this.config.ModeloAtividadeNo.toString());
          const uno = Base64.encode(this.config.usuarioLogadoNo.toString());
          l.href += `${separator}o=${ono}&p=${pno}&a=${ano}&u=${uno}`;
          l.href = l.href.replace('?&', '?');
          l.target = '_blank';
        }
        messageHtml = body[0].innerHTML;
      }
      // #endregion
      return messageHtml;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractHtmlBody', error.message);
    }
    return '';
  }

  /* Exibe uma mensagem de campos não preenchidos. */
  // tslint:disable-next-line: ban-types
  protected showInvalidFormMessage(): Observable<any> {
    try {
      const invalid = this.getInvalidComponents(this.formGroup, this.componentes);
      const message = {
        firstButtonLabel: this.msg.BUTTON_OK,
        title: this.msg.REQUIRED_FIELD_NOT_FILLED_TITLE,
        icon: 'fa-alert',
        text: `${this.msg.REQUIRED_FIELD_NOT_FILLED} <br /> <br /><span class="detail">${invalid}.</span>`,
        // acceptFunc
      } as IAlertMessage;
      return this.onAlertDialog(message, false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showInvalidFormMessage', error.message);
    }
    return of(null);
  }

  /* Atualiza as propriedades que são passadas para o rodapé. */
  updateRodapeAndProperties(ono: number, pno: number, tno: number, atividade: IAtividade, aInicio?: IAtividade): void {
    try {
      if (aInicio) {
        atividade.TarefaNome = aInicio.TarefaNome;
        atividade.DtInicio = aInicio.DtInicio;
        atividade.blAtividadeContinua = aInicio.blAtividadeContinua;
        atividade.HoraInicio = aInicio.HoraInicio;
        atividade.enPassoTipo = aInicio.enPassoTipo;
        atividade.PapelNo = aInicio.PapelNo;
        atividade.ParalelismoId = aInicio.ParalelismoId;
        this.atividadeNome = aInicio.Nome;
        this.config.ModeloAtividadeNo = aInicio.AtividadeNo;
      } else {
        this.config.ModeloAtividadeNo = atividade.AtividadeNo;
        this.atividadeNome = atividade.Nome;
      }
      atividade.OcorrenciaNo = ono;
      this.atividade = atividade || aInicio;
      this.tarefaNo = tno;
      this.ocorrenciaNo = ono;
      this.config.OcorrenciaNo.next(ono);
      this.config.processoNo = pno;
      // this.tarefaNo,
      // this.config.OcorrenciaNo.value,
      // this.config.usuarioLogadoNo,
      // this.config.processoNo,
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateRodape', error.message);
    }
  }

  // tslint:disable-next-line: max-line-length
  // http://stackoverflow.com/questions/36997625/angular-2-communication-of-typescript-functions-with-external-js-libraries/36997723#36997723
  /* Esse evento será executado externamente ao projeto, para permitir comunicação com a intranet e/ou projetos javascript externos. */
  // Desativado, pois não está sendo utilizado (passagem de parametro feita via cookie) e pode gerar brecha de segurança
  // @HostListener('window:updateValues', ['$event'])
  // updateValues($event: any): void {
  //   try {
  //     console.log("AtividadeView", "evento disparado", "updateValues", $event);
  //     this.updateFormValues($event.detail);
  //     // let item = $event.detail;
  //     // for (let clm in item) {
  //     //   let obj = {};
  //     //   obj[clm] = item[clm];
  //     //   if (this.formGroup) {
  //     //     this.formGroup.patchValue(obj, { onlySelf: true, emitEvent: true });
  //     //     this.formGroup.updateValueAndValidity();
  //     //   }
  //     // }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'updateValues', error.message);
  //   }
  // }

  // Adicionar na index
  // <script>
  // $('#btnEvent').click(dispatchUpdateValues);
  // $(document).ready(function () {
  // window.setTimeout(dispatchUpdateValues, 5000);
  // });
  // function dispatchUpdateValues() {
  //   var evt = new CustomEvent(
  //     'updateValues', {
  //       detail: {
  //         "V_4651": -100,
  //         "V_4647": 'ALUGUEL',
  //         "V_4649": '01/03/2017',
  //         "V_27908": 'SIM'
  //       }
  //     });
  //   window.dispatchEvent(evt);
  // window.postMessage(evt, "*");
  // console.log('Btn tentativa disparar evento updateValues');
  // }
  // </script>
}
