import { IIntervecoesMedicamentosHorarios } from './iintervencoes-medicamentos-horarios';
import { IMedicationCheckin } from './imedication-checkin';

export interface IInterventionMedication {
  tempoUsoId?: number;
  guid?: string;
  ocorrenciaNo: number;
  medicamento: string;
  tempoUso: string;
  dosagem: any;
  dosagemValor?: string; 
  dosagemUnidade?: string; 
  via: string;
  orientacoes: string;
  posologia: string;
  centrocusto: any;
  codigoPaciente: any;
  apresentacao: any;
  quantUtilizadaD: any;
  quantUtilizadaGota: any;
  hojedia: Date | string;
  codigo: any;
  titulo: any;
  habilitado: boolean;
  // codigomedicamento: any; Não tem na geração atual. É o Id_Medicamento
  cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: any;
  centroCustoIDCentroCusto: any;
  tIPOMedicamentos: any;
  tipomedicamento: any;
  dia: any;
  codigoPacienteNomePaciente: any;
  tIPOMaterial: any;
  uNIDADEDENEGOCIO: any;
  validCadastro: any;
  codPacienteNomedoPacienteCodMedicamento: any;
  dataPRESCRICAO: Date | string;
  tipoMedicamentosCodPaciente: any;
  medicametnoPrescrito: any;
  horaprimeiraDose: any;
  codigoMedicamentoPaciente: any;
  dataEVOLUCAO: Date | string;

  lstmedicationCheckin: IMedicationCheckin[];
  MedicationCheckin: IMedicationCheckin[];

  // modificacao Rony
  Id_Paciente: string;
  Id_Medicamento: string;
  IdMedicamento_IdPaciente: string;
  interroperMedicacao: boolean;
  interrupcaoMotivo: string;
  calc__isSOS?: boolean; // Campo calculado: quando Posologia = SOS
  // Campo adicionado para nova posologia
  intervaloHoras?: number;
  // modificacao barbara
  horarios: any; //
  lsthorariosGrid: IIntervecoesMedicamentosHorarios[];
  // descricao: any;
  dataInicio: Date | string;
  dataFim: Date | string;
  medicamentoControlado: string;
  profissional: any;
  tempoEstimado: any;
  quantTempoEstimado: any;
  //  codMedPacTM: any;
  // cascataTmatHospe: any;
  codigoHospede: any;
  intervaloDS: any;
  // Nova tela de prescricao
  Id_Prescricao?: number;
  ordem: number;
  wasChanged?: boolean;
  materialId: number;
  cor?: string;
}
