import { EnPosology, GlobalService, IMedication, IMedicationCheckin, IPatient, LogService } from '@medlogic/shared/shared-interfaces';

const glb = new GlobalService();
const log = new LogService();

/* 
* STOP Medicação interrompida.
* Retorna S para tomou, 
* SH Tomou fora de horário. 
* N para Não tomou (não foi feito o checkin), mas deve ser tomado.
* - não está prescrito nesse dia (ou antes do início ou após o término do ocasional). 
* NJ Não tomou (Rejeitou) com justificativa.
* P deve tomar, mas está no futuro.
* patient não é usado, mas necessário para manter compatibilidade com status do vital sign.
* Mudança de conceito: Ao invés de considerar marcado o checkin conforme data e hora, comparar o
*/
export const getMedicationStatus = (patient: IPatient, medication: IMedication, date: Date, check: IMedicationCheckin): string => {
    try {

        // Checa os parâmetros mínimos necessários, caso não haja, retorna Não tomou.
        if (!date && !check) {
            return 'N';

            // Checa se a medicação está interrompida manualmente pelo usuário.
        } else if (medication.stopMedication) {
            return 'STOP';

            // Checa se está fora do período de vigência da medicação.
        } else if (
            glb.isFirstDateBiggerThanSecondIgnoringTimes(medication.dtStart, date) || // A data de início é posterior a data de referência
            (
                (medication.tempoUso?.toUpperCase() || '') === 'OCASIONAL' &&
                (
                    // A data de referência é menor que a data prevista para início, ou foi configurada a interrupção e a data é posterior a data de interrupção
                    glb.isFirstDateBiggerThanSecondIgnoringTimes(date, medication.dtEnd)
                )
            )
        ) {
            return '-'; // está fora do período de vigência da medicação, ou não começou, ou foi marcado para terminar numa data anterior da de referência

        } else if (check) { // Dentro do período de vigência e há check

            // Significa que o check foi realizado, mas o paciente NÃO TOMOU (REJEITOU) e foi apresentada justificativa.
            if (glb.isEqual(check.status, 'NJ')) {
                return 'NJ';
            }

            // Check realizado, paciente tomou: S - no horário, SH - fora de horário.
            return isOnPrescribedInterval(check) ? 'S' : 'SH';

        } else { // Como não há check mas está no período de vigência, verificará apenas se é uma prescrição atual ou futura

            // Data de referência posterior ao dia atual (HOJE), ou seja, está tentando checar uma data futura.
            if (glb.isFirstDateBiggerThanSecondIgnoringTimes(date, new Date())) {

                // Como é lançamento futuro e não foi checado, verifica se deverá ser ministrado no futuro.
                return checkIfDateIsOnMustToTakeMedicationPeriod(
                    date,
                    medication?.dtStart,
                    medication?.dtEnd,
                    medication?.enPosology,
                    medication?.intervaloDS,
                    medication?.stopMedication
                )
                    ? 'P' // Lançamento futuro, posterior a data atual, mas deve ser ministrado
                    : '-';  // Não deve ser ministrado
            }
            // TODO: Checar se deveria ser o N direto. Porque o status estaria "-"? Pode ter sido forçado fora dessa rotina?
            return medication.status === '-' ? '-' : 'N'; // Como não entrou em nenhuma condição, forçar ser - ou então N.
        }

    } catch (error) {
        log.Registrar('shared/utils/medication-status', 'getMedicationStatus', error.message);
        return ' ';
    }
}

/* Determina se o check foi dentro da tolerância de horário. */
function isOnPrescribedInterval(check: IMedicationCheckin, toleranceMin: number = 60): boolean {
    try {
        const checkTime = glb.getHora(check.horaCheckin); // , true);
        const prescribedTime = glb.getHora(check.horaPrescricao); // , true);
        return checkTime <= prescribedTime + (toleranceMin / 60) && checkTime >= prescribedTime - (toleranceMin / 60);
    } catch (error) {
        log.Registrar('shared/utils/medication-status', 'isOnPrescribedInterval', error.message);
    }
    return true;
}

/* Definirá se a data fornecida coincide com uma data na qual a medicação deveria ser tomada.
* Por exemplo: se a posologia for semanal e prescrita para iniciar em 01/12/2018 (sábado) e finalizar em 01/03/2019
* então deverá checar se a data fornecida é superior a 01/12/2018 (que é também a data de início),
* se é inferior a 01/03/2019, e se também coincide com uma das datas que têm intervalo de 7 em 7 dias.
*/
function checkIfDateIsOnMustToTakeMedicationPeriod(
    dtToCheck: Date,
    dtStart: Date,
    dtEnd: Date,
    enPosology: EnPosology,
    intervaloDS: any,
    stopMedication: boolean
): boolean {
    try {
        // A checagem de dtStart < dtEnd é necessária, pois, caso a medicação seja
        // 'Permanente', a dtEnd ficará oculta e igual a data do dia de preenchimento.
        if (glb.isEqualIgnoreTime(dtToCheck, dtStart)) {
            return false;
            // } else if (dtToCheck < dtStart || (dtToCheck > dtEnd && dtStart < dtEnd)) {
            // } else if (dtToCheck < dtStart || (stopMedication && dtToCheck > dtEnd)) {
        } else if (
            glb.isFirstDateBiggerThanSecondIgnoringTimes(dtStart, dtToCheck) ||
            stopMedication && glb.isFirstDateBiggerThanSecondIgnoringTimes(dtToCheck, dtEnd)
        ) {
            return true;
        }
        let dayInterval = 1;
        const posology = enPosology ? enPosology.toUpperCase() : '';
        switch (posology) {
            default:
            case EnPosology.p1perDay:
                dayInterval = 1;
                break;
            case EnPosology.perDay:
                dayInterval = intervaloDS;
                break;
            case EnPosology.perWeek:
                dayInterval = intervaloDS * 7;
        }
        const diff = glb.dateDiffDays(dtStart, dtToCheck);
        return diff % dayInterval !== 0;
    } catch (error) {
        log.Registrar('shared/utils/medication-status', 'checkIsDateOnMustToTakePeriod', error.message);
    }
    return false;
}
